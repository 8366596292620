import moment from 'moment';
import stripComments from 'strip-comments';
import { BLOCKTHROUGH_ORG_ID, DOMAIN_REGEX, ERROR_CODES, HTTP_REGEX } from './constants';

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeEachWord(s) {
  if (typeof s !== 'string') return '';
  return s
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

/**
 * Used as an argument to a `<Array>.sort(...)` function
 * Returns:
 *  a NEGATIVE value if `a` comes BEFORE `b` in alphabetical order
 *  a POSITIVE value if `a` comes AFTER `b` in alphabetical order
 *  0 if `a` and `b` are alphabetically equivalent
 */
export function compareAlphabetically(a = '', b = '') {
  return a.localeCompare(b, 'en', { sensitivity: 'base' });
}

/**
 * Used as an argument to a `<Array>.sort(...)` function
 * Returns:
 *  a NEGATIVE value if `a` comes BEFORE `b` in chronological order
 *  a POSITIVE value if `a` comes AFTER `b` in chronological order
 *  0 if `a` and `b` are chronologically equivalent
 */
export function compareChronologically(a, b) {
  if (a && b) {
    return moment(a).unix() - moment(b).unix();
  } else if (a || b) {
    return a ? 1 : -1; // if `a` exists but `b` doesn't, `a` will be placed after `b` (and vice-versa)
  } else {
    return 0; // neither `a` nor `b` exist, they are chronological equivalents
  }
}

/**
 * Takes an array and returns a new alphabetically sorted copy.
 * @param {Array} arr Array of items to sort
 * @param {string} [sortKey] Can be passed in if `arr` is an array of objects to sort them by the key's associated value in each object.
 * @returns {Array} A new array of the sorted items
 */
export function sortAlphabetically(arr = [], sortKey) {
  const newArray = [...arr];
  return sortKey
    ? newArray.sort((a, b) => compareAlphabetically(a?.[sortKey], b?.[sortKey]))
    : newArray.sort(compareAlphabetically);
}

/**
 * Returns a sorted copy of the `orgs` provided, but with the Blockthrough org (returned from the `/OrgList` API response as the "All Organizations" org) set as the first entry of the new Array (if found).
 * @param {Array} orgs Array of orgs to sort
 * @returns {Array} The sorted orgs
 */
export function sortAlphabeticallyWithBTOrgFirst(orgs) {
  const sortedOrgs = sortAlphabetically(orgs, 'name');

  const btOrgIndex = sortedOrgs.findIndex(({ id }) => id === BLOCKTHROUGH_ORG_ID);
  const btOrg = sortedOrgs[btOrgIndex];

  return btOrg ? sortedOrgs.toSpliced(btOrgIndex, 1).toSpliced(0, 0, btOrg) : sortedOrgs;
}

/**
 * Tests the validitiy of a provided domain name (allows subdomains)
 */
export function validateWebsiteDomain(domain = '') {
  return DOMAIN_REGEX.test(domain);
}

/**
 * Returns a formatted version of the provided `domain` string
 * The formatting consists of:
 * - converting all the characters to lowercase (unless `convertToLowercase` is passed in and set to `false`)
 * - removing any (case-insensitive) instances of "http(s)://", "www.", or "http(s)://www."
 * - trimming leading/trailing whitespace
 */
export function formatWebsiteDomain(domain = '', { convertToLowercase = true } = {}) {
  let formattedDomain = convertToLowercase ? domain.toLowerCase() : domain;
  formattedDomain = formattedDomain.trim().replace(HTTP_REGEX, '');

  return formattedDomain;
}

/**
 * Returns unique lists of formatted versions of the valid & invalid domains found within the newline-delimited `domainsString` provided
 * (The return value takes the form of: `{ validDomains: [...], invalidDomains: [...] }`.)
 * NOTE: If `orgWebsites` is provided, a domain is considered valid if an associated website with the same domain (case-sensitive) is found in `orgWebsites`.
 */
export function parseValidWebsiteDomains(domainsString = '', { orgWebsites } = {}) {
  const convertToLowercase = !orgWebsites; // don't convert to lowercase if `orgWebsites` is provided, since there are some older org websites which are capitalized and which wouldn't be found if converted to lowercase

  const uniqueFormattedDomains = [
    ...new Set(
      domainsString
        .split('\n')
        .map((domain) => formatWebsiteDomain(domain, { convertToLowercase }))
        .filter((domain) => !!domain) // ignore any blank lines or "empty" domains (e.g. "www. ")
    ),
  ];

  return uniqueFormattedDomains.reduce(
    ({ validDomains, invalidDomains }, formattedDomain) => {
      const isValidDomain = orgWebsites
        ? orgWebsites.find((website) => website.domain === formattedDomain)
        : validateWebsiteDomain(formattedDomain);

      if (isValidDomain) {
        return { validDomains: [...validDomains, formattedDomain], invalidDomains };
      } else {
        return { validDomains, invalidDomains: [...invalidDomains, formattedDomain] };
      }
    },
    { validDomains: [], invalidDomains: [] }
  );
}

/**
 * Checks the validity of each supplied domain in the newline-delimited `domainsString` (the value of a typical "Domains" form field)
 * Returns a promise which: resolves with the `domainsString` value if valid, or rejects with an appropriate error message if invalid
 * NOTE: If `orgWebsites` is provided, a domain is considered valid if an associated website with the same domain (case-sensitive) is found in `orgWebsites`.
 */
export function validateWebsiteDomainsField(domainsString = '', { orgWebsites } = {}) {
  const { validDomains, invalidDomains } = parseValidWebsiteDomains(domainsString, { orgWebsites });

  if (invalidDomains.length === 0) {
    if (validDomains.length > 0) {
      return Promise.resolve(domainsString);
    } else {
      return Promise.reject(`Please enter at least 1 domain${orgWebsites ? ' from the org' : ''}!`);
    }
  } else {
    const invalidPlural = invalidDomains.length > 1;
    const numOtherInvalid = invalidDomains.length - 1;
    const otherInvalidPlural = numOtherInvalid > 1;
    return Promise.reject(
      `"${invalidDomains[0]}"${invalidPlural ? ` and ${numOtherInvalid} other domain${otherInvalidPlural ? 's' : ''}` : ''
      } ${orgWebsites
        ? `${invalidPlural ? `don't` : `doesn't`} exist in the org`
        : `${invalidPlural ? 'are not valid' : 'is not a valid domain'}`
      }!`
    );
  }
}

/**
 * Checks the validity of the supplied `domain` string (the value of a typical "Domain" form field)
 * Returns a promise which: resolves with the `domain` value if valid, or rejects with an appropriate error message if invalid
 */
export function validateWebsiteDomainField(domain = '') {
  return validateWebsiteDomain(formatWebsiteDomain(domain))
    ? Promise.resolve(domain)
    : Promise.reject('Please enter a valid domain!');
}

// Source: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
export function validateEmailAddress(emailAddress = '') {
  return emailAddress.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

// TODO: Maybe renmae `validateJsonField` to align with conventions above where validateX returns true/false and validateXField returns a promise
export function validateJson(jsonString) {
  let parsedJson;
  try {
    parsedJson = JSON.parse(jsonString);
  } catch (e) {
    return Promise.reject('JSON is invalid!');
  }
  return Promise.resolve(parsedJson);
}

export function validateSize(sizeString) {
  return sizeString === 'fluid' || '0x0' || /^[1-9]\d*x[1-9]\d*$/.test(sizeString);
}

/**
 * Transforms provided `space` object into a Prebid format ad unit
 * which the API accepts in the `/ImportAdUnits` call.
 * NOTE: `selector` is not part of the Prebid ad unit spec, it's BT-specific.
 */
export function createPrebidAdUnit(space) {
  const sizes = space.sizes.map((sizeString) =>
    sizeString.split('x').map((dimension) => parseInt(dimension, 10))
  );
  return {
    code: space.name,
    device_type: space.device_type,
    selector: { value: space.selector?.value || `[id="${space.name}"]` },
    bids: space.bidders.map(({ data }) => {
      const { bidder, params } = typeof data === 'string' ? JSON.parse(data) : data;
      return { bidder, params };
    }),
    sizes,
  };
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

function extractErrorMsg(error) {
  if (typeof error === 'string') {
    // if specific string provided vs. error obj, return string
    return error;
  }

  const messageFallback = error.message;

  // Use the API error message returned from the back end if one exists
  // (These messages can be overwritten/modified in the functions exported from `api.js` - see `api.signIn` for an example)
  if ('response' in error) {
    error = error.response?.data;
  }

  if (error?.code === 'unauthenticated') {
    // if it's a /SignIn error which uses a different error format, return specific string
    return 'we could not find an account with that email and password. Please try again';
  }

  if (error.meta?.error_code && ERROR_CODES[error.meta?.error_code]) {
    // if back end sends back an error code we recognize, try to use the error message they send back, otherwise use fallback stored in `ERROR_CODES[].text` for that specific error code
    const errorCode = error.meta.error_code;
    const originalErrMsg = error.meta?.msg ? error.meta?.msg : error.msg;
    const parsedErr = parseErrorMessage(originalErrMsg);

    return parsedErr ? parsedErr : ERROR_CODES[errorCode].text;
  }

  if (error.msg) {
    // if back end only sends back the full error text in `error.msg`, try to use the error message they send back
    return parseErrorMessage(error.msg);
  }

  if (messageFallback) {
    // if error object has a `message` property, use that
    return messageFallback;
  }

  // if all else fails, return 'unknown error'
  return ERROR_CODES['0'].text;
}

/**
 * Returns a standardized error message string
 */
export function generateErrorMessage(error) {
  return `Error: ${extractErrorMsg(error)}.`;
}

/**
 * Returns error msg string with unhelpful sections removed, if they exist.
 * e.g. if: `msg` is "error: ErrCode: 1, error: user with email name@blockthrough.com already exists",
 *      returns: "user with email name@blockthrough.com already exists"
 */
function parseErrorMessage(msg) {
  if (!msg) return;
  const msgSplit = msg.split('error:');
  let msgSnippet = msgSplit.length > 1 ? msgSplit[msgSplit.length - 1] : msg;
  msgSnippet = msgSnippet.replaceAll('validation', '');

  return msgSnippet;
}

export const formatWithThousandCommas = (num) =>
  num ? `${num}`.replace(/\d{1,3}(?=(\d{3})+(?:\.\d+)?$)/g, (x) => `${x},`) : '0';

// Uses same algorithm as the one in up-browser's `buildhooks.js` (which also removes empty lines)
export const removeCommentsFromJSCode = (jsCodeString = '') =>
  stripComments(jsCodeString).replace(/^\s*\n/gm, '');
