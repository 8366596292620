import axios from 'axios';
import React from 'react';
import { SWRConfig } from 'swr';

// process.env.REACT_APP_COMMIT_SHA;
// process.env.REACT_APP_COMMIT_VERSION;

export const PUBLIC_SERVICE_PATH = '/twirp/dashboard.Public';
export const PRIVATE_SERVICE_PATH = '/twirp/dashboard.Admin';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_DASH_API_URL,
});

// Handle 401 error (Unauthenticated) for calls to PRIVATE_SERVICE_PATH
export const setupAuthInterceptor = (dispatch) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 && error?.config?.url.startsWith(PRIVATE_SERVICE_PATH)) {
        // Dispatch unauthentication to global state whose change will trigger a redirect to `/login`
        dispatch({ type: 'UNAUTHENTICATE' });
      }

      return Promise.reject(error);
    }
  );
};

function getSession() {
  return instance.post(`${PRIVATE_SERVICE_PATH}/Session`, {}).then((response) => response.data);
}

function signIn(body) {
  return instance.post(`${PUBLIC_SERVICE_PATH}/SignIn`, body).then((response) => {
    return response.data;
  });
}
function signOut() {
  return instance.post(`${PRIVATE_SERVICE_PATH}/SignOut`, {}).then((response) => {
    return response.data;
  });
}

function forgotPassword(body) {
  return instance.post(`${PUBLIC_SERVICE_PATH}/UserForgotPassword`, body).then((response) => {
    return response.data;
  });
}
function setPassword(body) {
  return instance.post(`${PRIVATE_SERVICE_PATH}/UserSetPassword`, body).then((response) => {
    return response.data;
  });
}

// Used in /OrgList & /WebsiteList API calls to omit extra data we don't need from the response (e.g. hooks, which can be quite large)
const orgFilters = {
  prod_params: false,
  stg_params: false,
};
const websiteFilters = {
  prod_params: true,
  stg_params: false,
  prod_params_hook: false,
  stg_params_hook: false,
};

function rlDefaultTranslations(lang) {
  lang = lang.toLowerCase();
  const url = `${process.env.REACT_APP_RL_CDN}/translations/${lang}.json`

  return fetch(url)
    .then(r => r.json())
};


function listOrgs(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgList`, {
      params: body,
      with_filters: orgFilters,
    })
    .then((response) => response.data.orgs || []);
}
function searchOrgs(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgSearch`, {
      params: body,
    })
    .then((response) => response.data.orgs || []);
}
function createOrg(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgCreate`, { params: body })
    .then((response) => response.data);
}
function updateOrg(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgUpdate`, { params: body })
    .then((response) => response.data);
}
function orgExportAdUnits(body) {
  const path = `/orgs/${body.org_id}/adunits.csv`;
  return (
    instance
      .get(path, { timeout: 5000 })
      // we need to redirect to the CSV url if it doesn't time out in order for the file to download
      .then(() => (window.location.href = `${process.env.REACT_APP_DASH_API_URL}${path}`))
  );
}
function orgEmailAdUnits(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgExportAdUnits`, body)
    .then((response) => response.data);
}

function listWebsites(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteList`, {
      params: body,
      with_filters: websiteFilters,
    })
    .then((response) => response.data.websites || []);
}
function searchWebsites(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteSearch`, {
      params: body,
    })
    .then((response) => response.data.websites || []);
}
function createWebsite(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteCreate`, { params: body })
    .then((response) => response.data);
}
function websiteExportAdUnits(body) {
  const path = `/websites/adunits.csv?${body.selectedWebsiteIds}`;
  return (
    instance
      .get(path, { timeout: 5000 })
      // we need to redirect to the CSV url if it doesn't time out in order for the file to download
      .then(() => (window.location.href = `${process.env.REACT_APP_DASH_API_URL}${path}`))
  );
}
function websiteEmailAdUnits(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteExportAdUnits`, body)
    .then((response) => response.data);
}
/**
 * API call to create multiple websites at once
 *
 * @param {Object} body
 * @param {string} body.org_id
 * @param {{ domain: string }[]} body.params
 * @param {Object} [body.with_filter]
 * @param {boolean} [body.with_filter.prod_params]
 * @param {boolean} [body.with_filter.stg_params]
 * @param {boolean} [body.with_filter.prod_params_hook]
 * @param {boolean} [body.with_filter.stg_params_hook]
 * @param {boolean} [body.with_filter.gam_enabled_records]
 *
 * @returns {Promise<Object>}
 */
function createWebsites(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteBulkCreate`, body)
    .then((response) => response.data);
}
function updateWebsite(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteUpdate`, { params: body })
    .then((response) => response.data);
}
function updateWebsites(params) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteBulkUpdate`, { params })
    .then((response) => response.data);
}
function deleteWebsite(websiteId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WebsiteDelete`, { id: websiteId })
    .then((response) => response.data);
}

function listSpaces(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/SpaceList`, { params: body })
    .then((response) => response.data.spaces || []);
}
function updateSpace(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/SpaceUpdate`, { params: body })
    .then((response) => response.data);
}
function uploadSpaces(website_id, spaces, staging) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ImportAdUnits`, {
      website_id,
      adunits: spaces,
      staging,
    })
    .then((response) => response.data);
}
function deleteSpace(spaceId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/SpaceDelete`, { id: spaceId })
    .then((response) => response.data);
}
function deleteSpaces(spaceIds) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/SpaceBulkDelete`, { ids: spaceIds })
    .then((response) => response.data);
}

function listBidders(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/BidderList`, { params: body })
    .then((response) => response.data.bidders || []);
}
function updateBidder(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/BidderUpdate`, { params: body })
    .then((response) => response.data);
}
function deleteBidder(bidderId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/BidderDelete`, { id: bidderId })
    .then((response) => response.data);
}

function listUsers(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserList`, { params: body })
    .then((response) => response.data.users || []);
}
function listOrgUsers(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/OrgListUsers`, body)
    .then((response) => response.data.users || []);
}
function createUser(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserCreate`, { params: body })
    .then((response) => response.data);
}
function updateUser(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserUpdate`, { params: body })
    .then((response) => response.data);
}
function deleteUser(userId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserDelete`, { id: userId })
    .then((response) => response.data);
}
function inviteNewUserToOrg(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/InviteCreate`, { params: body })
    .then((response) => ({
      // Append the newly invited user's email to the response (since the back end doesn't send it back to us)
      invite: { ...response.data.invite, user_email: body.email },
    }));
}
function addUserToOrg(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserAddOrgToRole`, body)
    .then((response) => response.data);
}
function removeUserFromOrg(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/UserRemoveOrgFromRole`, body)
    .then((response) => response.data);
}

function listGAMNetworks(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/GAMNetworkList`, { params: body })
    .then((response) => response.data.gam_networks || []);
}
function createGAMNetwork(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/GAMNetworkCreate`, { params: body })
    .then((response) => response.data);
}
function deleteGAMNetwork(networkId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/GAMNetworkDelete`, { id: networkId })
    .then((response) => response.data);
}
function updateGAMNetwork(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/GAMNetworkUpdate`, { params: body })
    .then((response) => response.data);
}

function listRevShares(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RevShareList`, { params: body })
    .then((response) => response.data.rev_shares || []);
}
function createRevShare(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RevShareCreate`, { params: body })
    .then((response) => response.data);
}
function updateRevShare(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RevShareUpdate`, { params: body })
    .then((response) => response.data);
}
function deleteRevShare(revShareId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RevShareDelete`, { id: revShareId })
    .then((response) => response.data);
}

function listWhitelistRules(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WhitelistRulesRecordList`, { params: body })
    .then((response) => response.data.records);
}
function createWhitelistRules(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/WhitelistRulesRequest`, body)
    .then((response) => response.data);
}

function getPageviewMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/PageViewMetrics`, body)
    .then((response) => response.data);
}
function getAAPageviewMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/AAPageViewMetrics`, body)
    .then((response) => response.data);
}
function getAuctionMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/AuctionMetrics`, body)
    .then((response) => response.data);
}
function getImpressionMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ImpressionMetrics`, body)
    .then((response) => response.data);
}
function getGrossRevenueMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/GrossRevenueMetrics`, body)
    .then((response) => response.data);
}
function getPayableRevenueMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/PayableRevenue`, body)
    .then((response) => response.data);
}
function getCPMMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/CPMMetrics`, body)
    .then((response) => response.data);
}
function getRPMMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RPMMetrics`, body)
    .then((response) => response.data);
}
function getTopSitesMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/TopSites`, body)
    .then((response) => response.data.top_sites);
}
function getMessageWallRecoveryMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/MWRMetrics`, body)
    .then((response) => response.data.metrics);
}
function getAdblockerBreakdownMetrics(body) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/RLAdblockerMetrics`, body)
    .then((response) => response.data.metrics);
}

function listReports(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportList`, body)
    .then((response) => response.data.reports);
}
function createReport(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportCreate`, { params: body })
    .then((response) => response.data);
}
function updateReport(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportUpdate`, { params: body })
    .then((response) => response.data);
}
function deleteReport(reportId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportDelete`, { id: reportId })
    .then((response) => response.data);
}
function deleteReports(reportIds) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportBulkDelete`, { ids: reportIds })
    .then((response) => response.data);
}
function generateReport(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportExecuteOnly`, { params: body })
    .then((response) => response.data);
}
function generateReports(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportExecute`, body)
    .then((response) => response.data);
}
function duplicateReports(reportIds, orgId) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportClone`, { ids: reportIds, org_id: orgId })
    .then((response) => response.data);
}
function getReportFields(orgId, isMessageWall) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/ReportMetricsOptions`, {
      org_id: orgId,
      is_message_wall: isMessageWall,
    })
    .then((response) => response.data);
}

function listLineItems(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobList`, { params: body })
    .then((response) => ({ jobs: response.data.jobs, refreshTime: Date.now() }));
}
function createLineItem(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobCreate`, { params: body })
    .then((response) => response.data);
}
function deleteLineItem(id) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobDelete`, { id })
    .then((response) => response.data);
}
function updateLineItem(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobUpdate`, { params: body })
    .then((response) => response.data);
}
function triggerLineItemJob(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobTrigger`, { params: body })
    .then((response) => response.data);
}
function listLineItemJobRuns(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/LineItemJobRunList`, { params: body })
    .then((response) => response.data.job_runs);
}

function getOrgAuditLog(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/AuditLogOrgList`, body)
    .then((response) => response.data);
}
function getWebsiteAuditLog(body = {}) {
  return instance
    .post(`${PRIVATE_SERVICE_PATH}/AuditLogWebsiteList`, body)
    .then((response) => response.data);
}

/**
 * Sets global config options for all SWR hooks
 */
export const SWRProvider = ({ children }) => (
  <SWRConfig
    value={{
      fetcher: swrFetcher,
      refreshInterval: 0,
      shouldRetryOnError: false,
    }}
  >
    {children}
  </SWRConfig>
);

/**
 * Used as the default/fallback for all SWR hooks when no fetcher is provided
 */
function swrFetcher(swrKey) {
  const [path, entityId] = Array.isArray(swrKey) ? swrKey : [swrKey];

  const isListCall = path.endsWith('List');
  const entityName = path
    .replace(/\/([A-Z].*?)[A-Z][a-z]*/, '$1')
    .replace(/^./, (firstChar) => firstChar.toLowerCase());

  const bodyParams = entityId ? { id: entityId } : {};
  const requestBody = isListCall ? { params: bodyParams } : bodyParams;
  if (isListCall && (entityName === 'org' || entityName === 'website')) {
    requestBody.with_filters = entityName === 'org' ? orgFilters : websiteFilters;
  }
  const propInResponseData = isListCall ? `${entityName}s` : entityName;

  return instance
    .post(`${PRIVATE_SERVICE_PATH}${path}`, requestBody)
    .then((response) => response.data[propInResponseData]);
}

const api = {
  addUserToOrg,
  createGAMNetwork,
  createLineItem,
  createOrg,
  createReport,
  createRevShare,
  createUser,
  createWebsite,
  createWebsites,
  createWhitelistRules,
  deleteBidder,
  deleteGAMNetwork,
  deleteLineItem,
  deleteReport,
  deleteReports,
  deleteRevShare,
  deleteSpace,
  deleteSpaces,
  deleteUser,
  deleteWebsite,
  duplicateReports,
  forgotPassword,
  generateReport,
  generateReports,
  getAAPageviewMetrics,
  getAdblockerBreakdownMetrics,
  getAuctionMetrics,
  getCPMMetrics,
  getGrossRevenueMetrics,
  getImpressionMetrics,
  getMessageWallRecoveryMetrics,
  getOrgAuditLog,
  getPageviewMetrics,
  getPayableRevenueMetrics,
  getReportFields,
  getRPMMetrics,
  getSession,
  getTopSitesMetrics,
  getWebsiteAuditLog,
  inviteNewUserToOrg,
  listBidders,
  listGAMNetworks,
  listLineItemJobRuns,
  listLineItems,
  listOrgs,
  listOrgUsers,
  listReports,
  listRevShares,
  listSpaces,
  listUsers,
  listWebsites,
  listWhitelistRules,
  orgEmailAdUnits,
  orgExportAdUnits,
  removeUserFromOrg,
  searchOrgs,
  searchWebsites,
  setPassword,
  signIn,
  signOut,
  swrFetcher,
  triggerLineItemJob,
  updateBidder,
  updateGAMNetwork,
  updateLineItem,
  updateOrg,
  updateReport,
  updateRevShare,
  updateSpace,
  updateUser,
  updateWebsite,
  updateWebsites,
  uploadSpaces,
  websiteEmailAdUnits,
  websiteExportAdUnits,
  rlDefaultTranslations
};

export default api;
